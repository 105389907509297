@media screen and (max-width: 39.9375em) {
    div.episode-tile {
        width: 150px;
        height: 150px;
    }
    span.episode-title {
        font-size: 15pt;
    }
}
@media screen and (min-width: 40em) {
    div.episode-tile {
        width: 250px;
        height: 250px;
    }
    span.episode-title {
        font-size: 20pt;
    }
}

div.episode-tile {
    cursor: pointer;
    margin: 10px;
    border-radius: 50px;
    border: 10px solid #ffc265;
    display: flex;
    vertical-align: bottom;
    text-align: center;
}

span.episode-title {
    margin-left: auto;
    margin-right: auto;
    margin-top: auto;
    font-family: SpritzAndDelicious;
    color: white;
    text-shadow: 0 0 3px black
}