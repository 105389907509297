@font-face {
  font-family: SpritzAndDelicious;
  src: url("./fonts/SpritzAndDelicious.otf");
}

div.App {
  background: url('images/stars.jpg');
  background-repeat: repeat;
}

header.App-header {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 42px;
  border-bottom: 5px solid #ffc265;
  
  background-color: #ffe9c8;
  background: radial-gradient(circle, transparent 20%, #ffe9c8 20%, #ffe9c8 80%, transparent 80%, transparent), radial-gradient(circle, transparent 20%, #ffe9c8 20%, #ffe9c8 80%, transparent 80%, transparent) 15px 15px, linear-gradient(#ffc265 1.2000000000000002px, transparent 1.2000000000000002px) 0 -0.6000000000000001px, linear-gradient(90deg, #ffc265 1.2000000000000002px, #ffe9c8 1.2000000000000002px) -0.6000000000000001px 0;
  background-size: 30px 30px, 30px 30px, 15px 15px, 15px 15px;

  filter: drop-shadow(0 2px 6px rgb(0,0,0));
}

img#afq-logo {
  width: 32px;
  height: 32px;
  margin: 5px;
}

div#episode-tile-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  overflow-y: scroll;
  height: calc(100vh - 217px);
}

div#song-player {
  font-family: "Helvetica Neue",Helvetica,Roboto,Arial,sans-serif;
  position: fixed;
  bottom: 0px;
  width: 100vw;
  
  background-color: #ffe9c8;
  background: radial-gradient(circle, transparent 20%, #ffe9c8 20%, #ffe9c8 80%, transparent 80%, transparent), radial-gradient(circle, transparent 20%, #ffe9c8 20%, #ffe9c8 80%, transparent 80%, transparent) 15px 15px, linear-gradient(#ffc265 1.2000000000000002px, transparent 1.2000000000000002px) 0 -0.6000000000000001px, linear-gradient(90deg, #ffc265 1.2000000000000002px, #ffe9c8 1.2000000000000002px) -0.6000000000000001px 0;
  background-size: 30px 30px, 30px 30px, 15px 15px, 15px 15px;

  filter: drop-shadow(0 -2px 6px rgb(0,0,0));
  border-top: 5px solid #ffc265;
}

span.song-player-current-time {
  font-weight: bold;
  position: relative;
  float: right;
  margin-right: 5px
}

span.duration {
  font-weight: bold;
  position: relative;
  float: left;
  margin-left: 5px
}

div#song-player-progress {
  display: flex;
  position: relative;
  margin: auto;
  margin-top: 5px;
  width: 50%;
}

div#progress-container {
  position: relative;
  width: 100%;
  height: 20px;
  margin: auto;
  cursor: pointer;
}

input[type=range]::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  height: 20px;
  width: 20px;
  border-radius: 10px;
  border: 2px solid #333333;
  background-color: #6c4371;
}

div#progress-container input[type=range] {
  -webkit-appearance: none;
  width: 100%;
  margin: 7.5px 0;
  position: absolute;
  z-index: 9999;
  top: -7px;
  height: 20px;
  cursor: pointer;
  background-color: inherit;
}

div#progress-container progress#song-played-progress[value]::-webkit-progress-bar {
  background: none;
  border-radius: 5px;
}
div#progress-container progress#song-played-progress[value]::-webkit-progress-value {
  background-color: #6c4371;
  border-radius: 5px;
}
progress#song-played-progress {
  width: 100%;
  position: absolute;
  left: 0;
  top: 8px;
  right: 0;
  width: 100%;
  z-index: 60;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  height: 4px;
  border-radius: 5px;
  background: transparent;
  border: none;
}

progress#song-buffered-progress[value]::-webkit-progress-bar {
  background-color: #CFD8DC;
  border-radius: 5px; }
progress#song-buffered-progress[value]::-webkit-progress-value {
  background-color: #78909C;
  border-radius: 5px;
  transition: width .1s ease; }
div#progress-container progress#song-buffered-progress {
  position: absolute;
  left: 0;
  top: 8px;
  right: 0;
  width: 100%;
  z-index: 10;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  height: 4px;
  border-radius: 5px;
  background: transparent;
  border: none;
  background-color: #D7DEE3;
}

div#song-player-controls {
  display: block;
  position: relative;
  margin: auto;
  width: 320px;
  height: 64px;
  margin-top: 5px;
  margin-bottom: 5px;
}

div#play-pause, div#previous, div#next, div#repeat, div#shuffle {
  display: inline-block;
  width: 64px;
  height: 64px;
  cursor: pointer;
  float: left;
}
div#play-pause.amplitude-paused {
  background: url("images/play.png");
  background-size: cover;
}
div#play-pause.amplitude-playing {
  background: url("images/pause.png");
  background-size: cover;
}
div#previous {
  background: url("images/previous.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 48px 48px;
}
div#next {
  background: url("images/next.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 48px 48px;
}
div#shuffle.amplitude-shuffle-off {
  background: url("images/shuffle-off.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 32px 32px;
}
div#shuffle.amplitude-shuffle-on {
  background: url("images/shuffle.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 32px 32px;
}
div#repeat.amplitude-repeat-off {
  background: url("images/repeat-off.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 32px 32px;
}
div#repeat.amplitude-repeat-on {
  background: url("images/repeat.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 32px 32px;
}
div#song-player-metadata {
  text-align: center;
  margin-bottom: 10px;
}
span.song-name {
  font-family: 'SpritzAndDelicious';
  font-size: 14pt;
  font-weight: bold;
}
div.song-album {
  font-family: 'SpritzAndDelicious';
  font-size: 12pt;
}

/* Small only */
@media screen and (max-width: 39.9375em) {
  
}

/* Medium only */
@media screen and (min-width: 40em) and (max-width: 63.9375em) {

}

/* Large and up */
@media screen and (min-width: 64em) {

}

body {
  height: 100vh;
  -webkit-font-smoothing: antialiased;
  font-family: "Helvetica Neue",Helvetica,Roboto,Arial,sans-serif;
  line-height: 1.5;
}